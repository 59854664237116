<template>
  <div
    class="card mt-4"
    style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
  >
    <div class="card-header">Balance del día</div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-bordered mb-0">
          <tbody>
            <tr>
              <th>Esperado</th>
              <td class="text-right">
                {{ montoEsperado | formatMontoBalance }}
              </td>
            </tr>
            <tr>
              <th>Recibido</th>
              <td class="text-right">
                {{ montoRecibido | formatMontoBalance }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turnos"],
  computed: {
    montoEsperado() {
      let monto = 0;
      this.turnos.forEach((t) => (monto += t.monto || 0));
      return monto;
    },
    montoRecibido() {
      let monto = 0;
      this.turnos
        .filter((t) => t.estado === "Ya se atendió")
        .forEach((t) => (monto += t.monto || 0));
      return monto;
    },
  },
};
</script>


<style lang="scss" scoped>
.card-header {
  background: linear-gradient(to right, rgb(150, 47, 191), rgb(214, 41, 118));
  color: white;
}
</style>