<template>
  <div
    class="modal fade"
    id="pacientesSinProximoTurnoModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="pacientesSinProximoTurnoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-fullscreen" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
          
            id="pacientesSinProximoTurnoModalLabel"
          >
            Pacientes sin próximo turno
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Cerrar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="table-responsive" v-if="turnos.length">
            <table class="table table-bordered mb-0">
              <thead>
                <th>Días</th>
                <th>Fecha y Hora</th>
                <th>Paciente</th>
                <th>Zonas</th>
                <th>Sesión</th>
                <th>Monto</th>
                <th>MP</th>
                <th>Sucursal</th>
                <th>Comentarios</th>
              </thead>
              <tbody>
                <tr
                  v-for="turno in turnos"
                  :key="turno.id"
                  data-dismiss="modal"
                  data-toggle="modal"
                  @click="callbackPlanilla(turno.fecha, turno.sucursal)"
                >
                  <td>{{ turno.dias }}</td>
                  <td>
                    {{ turno.fecha | formatFecha }}
                    <br />
                    {{ turno.hora | formatHora2 }}
                  </td>
                  <td>
                    <template v-if="turno.alex">
                      <strong>
                        <u>ALEX</u>
                      </strong>
                      <br />
                    </template>
                    {{ turno.paciente }}
                  </td>
                  <td>
                    <template v-for="zona in JSON.parse(turno.zonas)">
                      {{ zona }}
                      <hr :key="zona" />
                    </template>
                  </td>
                  <td>{{ turno.sesion }}</td>
                  <td>{{ turno.monto | formatMontoTabla }}</td>
                  <td>{{ turno.mp }}</td>
                  <td>{{ turno.sucursal }}</td>
                  <td>{{ turno.comentarios }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turnos", "callbackPlanilla"],
};
</script>

<style lang="scss" scoped>
td hr {
  margin: 0.25rem 0;
  &:last-child {
    display: none;
  }
}
tr {
  cursor: pointer;
}
th:nth-child(3) {
  width: 10rem;
}
th:last-child {
  width: 2rem;
}
</style>
