<template>
  <div
    class="modal fade"
    id="modalEditarSucursales"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalEditarSucursales"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalSucursalesLabel">Sucursales</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Cerrar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            class="col-12 mt-4"
            v-for="sucursal in sucursales"
            :key="sucursal.sucursal"
          >
            <div class="row">
              <div class="col-4">
                <label for="nombreSucursalInput">Nombre sucursal</label>
                <input
                  type="text"
                  class="form-control"
                  id="nombreSucursalInput"
                  disabled
                  v-model="sucursal.sucursal"
                  placeholder="Nombre sucursal"
                />
              </div>
              <div class="col-4">
                <label for="direccionSucursalInput">Direccion</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="sucursal.direccion"
                  id="direccionSucursalInput"
                  placeholder="Direccion"
                />
              </div>
              <div class="col-3">
                <label for="entreCallesSucursalInput">Entre calles</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="sucursal.entreCalles"
                  id="entreCallesSucursalInput"
                  placeholder="Entre calles"
                />
              </div>
              <div class="col-1 d-flex align-items-center">
                <div class="form-check">
                  <input
                    v-model="sucursal.activo"
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="defaultCheck1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="editarSucursales"
          >
            Editar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["callbackSucursales", "sucursales"],
  methods: {
    async editarSucursales() {
      try {
        console.log(this.sucursales)
        this.sucursales.forEach((sucursal) => {
          this.axios.post("sucursales/editar", sucursal);
          this.callbackSucursales();
          const sucursalStorage = JSON.parse(localStorage.getItem("sucursal"));
          if (sucursalStorage.sucursal == sucursal.sucursal) {
            sucursal.activo == true
              ? (sucursal.activo = 1)
              : (sucursal.activo = 0);
            localStorage.setItem("sucursal", JSON.stringify(sucursal));
          }
        });
      } catch (err) {
        console.log(err)
        alert("Ya existe una sucursal con ese nombre");
      }
    },
  },
};
</script>
