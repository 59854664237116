<template>
  <div
    class="modal fade"
    id="eliminarTurnoModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="eliminarTurnoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="eliminarTurnoModalLabel">Eliminar turno</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          ¿Está seguro que desea eliminar el turno de la paciente
          <strong>{{ turno.paciente }}</strong>?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="eliminarTurno"
          >Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turno", "callbackTurnos"],
  methods: {
    eliminarTurno() {
      let loader = this.$loading.show();
      this.axios
        .post("turnos/eliminar", { id: this.turno.id })
        .then(() => this.callbackTurnos())
        .finally(loader.hide());
    }
  }
};
</script>
