<template>
  <div
    class="card mt-4"
    style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
  >
    <div class="card-header">Índices del día</div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-bordered mb-0">
          <thead>
            <th>Concepto</th>
            <th>Esperado</th>
            <th>Real</th>
            <th>%</th>
          </thead>
          <tbody>
            <tr>
              <th>Ocupación</th>
              <td>{{ ocupacionEsperado }}</td>
              <td>{{ ocupacionReal }}</td>
              <td>%{{ ocupacionRealP }}</td>
            </tr>
            <tr>
              <th>Asistencia</th>
              <td>{{ asistenciaEsperado }}</td>
              <td>{{ asistenciaReal }}</td>
              <td>%{{ asistenciaRealP }}</td>
            </tr>
            <tr>
              <th>Próximo turno asignado</th>
              <td>{{ proximoTurnoEsperado }}</td>
              <td>{{ proximoTurnoReal }}</td>
              <td>%{{ proximoTurnoRealP }}</td>
            </tr>
            <tr>
              <th>Balance</th>
              <td>${{ balanceEsperado }}</td>
              <td>${{ balanceReal }}</td>
              <td>%{{ balanceRealP }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turnos"],
  computed: {
    ocupacionEsperado() {
      return this.$horarios.length;
    },
    ocupacionReal() {
      return this.turnos.filter((t) => t.id).length;
    },
    ocupacionRealP() {
      return Math.round(
        (100 * this.ocupacionReal) / (this.ocupacionEsperado || 1)
      );
    },

    asistenciaEsperado() {
      return this.turnos.filter((t) => t.id).length;
    },
    asistenciaReal() {
      return this.turnos.filter((t) => t.estado === "Ya se atendió").length;
    },
    asistenciaRealP() {
      return Math.round(
        (100 * this.asistenciaReal) / (this.asistenciaEsperado || 1)
      );
    },

    proximoTurnoEsperado() {
      return this.turnos.filter((t) => t.id).length;
    },
    proximoTurnoReal() {
      return this.turnos.filter((t) => t.proximoTurno).length;
    },
    proximoTurnoRealP() {
      return Math.round(
        (100 * this.proximoTurnoReal) / (this.proximoTurnoEsperado || 1)
      );
    },

    balanceEsperado() {
      let monto = 0;
      this.turnos.forEach((t) => (monto += t.monto || 0));
      return monto;
    },
    balanceReal() {
      let monto = 0;
      this.turnos
        .filter((t) => t.estado === "Ya se atendió")
        .forEach((t) => (monto += t.monto || 1));
      return monto;
    },
    balanceRealP() {
      return Math.round((100 * this.balanceReal) / (this.balanceEsperado || 1));
    },
  },
};
</script>


<style lang="scss" scoped>
.card-header {
  background: linear-gradient(to right, rgb(150, 47, 191), rgb(214, 41, 118));
  color: white;
}
</style>