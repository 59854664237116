<template>
  <div
    class="modal fade"
    id="dejarComentariosModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dejarComentariosModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="dejarComentariosModalLabel">Dejar comentarios</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <textarea class="form-control" id="comentariosTextarea" rows="3" v-model="comentarios"></textarea>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="dejarComentario"
          >Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turno"],
  data() {
    return {
      comentarios: ""
    };
  },
  watch: {
    turno() {
      this.comentarios = this.turno.comentarios;
    }
  },
  methods: {
    dejarComentario() {
      this.turno.comentarios = this.comentarios.trim();

      let loader = this.$loading.show();
      this.axios.post("turnos/editar", this.turno).finally(loader.hide());
    }
  }
};
</script>
