<template>
  <div
    class="card mt-4"
    style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
  >
    <div class="card-header">Pacientes sin próximo turno</div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table table-bordered mb-0">
          <col width="*" />
          <col width="200" />
          <tbody>
            <tr v-for="turno in sinProximoTurno" :key="turno.id">
              <th>{{ turno.paciente }}</th>
              <td>
                <a
                  :href="`https://wa.me/${turno.celular}`"
                  target="_blank"
                  class="btn btn-success text-white"
                  >Enviar mensaje</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turnos"],
  computed: {
    sinProximoTurno() {
      return this.turnos.filter((t) => t.id && !t.proximoTurno);
    },
  },
};
</script>


<style lang="scss" scoped>
.card-header {
  background: linear-gradient(to right, rgb(150, 47, 191), rgb(214, 41, 118));
  color: white;
}
</style>
