<template>
  <div class="row">
    <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div
        class="card card-signin"
        style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
      >
        <div class="card-body">
          <h5 class="card-title text-center">Iniciar sesión</h5>
          <form class="form-signin" @submit.prevent="ingresar">
            <div class="form-group">
              <label for="inputIdentificador">Identificador</label>
              <input
                type="text"
                id="inputIdentificador"
                class="form-control"
                placeholder="AAA123"
                v-model="identificador"
                required
                autofocus
              />
            </div>

            <button
              class="btn btn-lg btn-primary btn-block text-uppercase"
              type="submit"
            >
              Ingresar
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      identificador: "",
    };
  },
  methods: {
    ingresar() {
      let loader = this.$loading.show();
      this.axios
        .post(`ingresar/recepcionista/${this.identificador}`)
        .then((r) => {
          if (r.data) {
            localStorage.setItem("dia", moment().format("YYYY-MM-DD"));
            localStorage.setItem("sucursal", JSON.stringify(r.data.sucursal));
            localStorage.setItem("ingresado", true);
            localStorage.setItem("recepcionista", true);
            this.$router.push("/");
          }
        })
        .finally(loader.hide());
    },
  },
};
</script>

<style lang="scss" scoped>
.card-signin {
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-title {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 1.5rem;
}

.card-signin .card-body {
  padding: 2rem;
}

.form-signin {
  width: 100%;
}

.form-signin .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  transition: all 0.2s;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input {
  height: auto;
  border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
</style>
