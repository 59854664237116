<template>
  <div>
    <div
      class="w-100 card"
      style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
      v-if="!$usuarioRecepcionista()"
    >
      <div class="card-body">
        <button
          type="button"
          data-toggle="modal"
          href="#historialDeTurnosModal"
          class="btn btn-warning btn-block btn-lg"
          style="
            background: linear-gradient(to right, #962fbf, #d62976);
            color: white;
            border: none;
          "
        >
          Historial de turnos
        </button>
        <button
          type="button"
          data-toggle="modal"
          href="#pacientesSinProximoTurnoModal"
          class="btn btn-danger btn-block btn-lg"
          style="
            background: linear-gradient(to right, #d62976, #fa7e1e);
            border: none;
          "
        >
          Pacientes sin próximo turno
        </button>
      </div>
    </div>

    <div class="row justify-content-center mt-4">
      <div class="col-12 col-md-6">
        <div
          class="card"
          style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
        >
          <div class="card-header d-flex justify-content-between">
            Sucursal
            <div>
              <span
                class="mr-4"
                style="cursor: pointer; font-size: 16px"
                data-toggle="modal"
                href="#modalEditarSucursales"
              >
                Editar
              </span>

              <span
                style="cursor: pointer; font-size: 16px"
                data-toggle="modal"
                href="#modalSucursales"
              >
                Agregar
              </span>
            </div>
          </div>
          <div class="card-body">
            <select
              class="form-control"
              v-model="sucursal"
              @change="cambiarParametros"
              :disabled="$usuarioRecepcionista()"
            >
              <option
                v-for="_sucursal in sucursales"
                :value="_sucursal"
                :key="_sucursal.sucursal"
              >
                {{ _sucursal.sucursal }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-4 mt-md-0">
        <div
          class="card"
          style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
        >
          <div class="card-header">Fecha</div>
          <div class="card-body">
            <select
              class="form-control"
              v-model="fecha"
              @change="cambiarParametros"
              :disabled="$usuarioRecepcionista()"
            >
              <option v-for="_fecha in fechas" :value="_fecha" :key="_fecha.id">
                {{ _fecha.fecha | formatFecha }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card mt-4"
      style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
    >
      <div class="card-header">Turnos</div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered mb-0">
            <thead>
              <th>Hora</th>
              <th>Paciente</th>
              <th>Zonas</th>
              <th>Sesión</th>
              <th>Monto</th>
              <th>MP</th>
              <th>Próximo turno</th>
              <th>Comentarios</th>
            </thead>
            <tbody>
              <tr
                data-toggle="modal"
                data-target="#opcionesModal"
                v-for="turno in turnos"
                :key="turno.id"
                :class="{ grisado: !turno.id }"
                :style="{
                  background: getColorFondo(turno.estado),
                  color: getColorTexto(turno.estado),
                }"
                @click="setTurno(turno)"
              >
                <td>{{ turno.hora }}</td>
                <td>
                  <template v-if="turno.alex">
                    <strong>
                      <u>ALEX</u>
                    </strong>
                    <br />
                  </template>
                  {{ turno.paciente }}
                </td>
                <td>
                  <template v-for="zona in turno.zonas">
                    {{ zona }}
                    <hr :key="zona" />
                  </template>
                </td>
                <td>{{ turno.sesion }}</td>
                <td>{{ turno.monto | formatMontoTabla }}</td>
                <td>{{ turno.mp }}</td>
                <td>
                  {{ turno.proximoTurno | formatFecha }}
                  <br />
                  {{ turno.proximoTurno | formatHora }}
                </td>
                <td>{{ turno.comentarios }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <sin-proximo-turno
      v-if="!$usuarioRecepcionista()"
      :turnos="turnos"
    ></sin-proximo-turno>

    <balance v-if="$usuarioAdmin()" :turnos="turnos"></balance>

    <indices v-if="$usuarioAdmin()" :turnos="turnos"></indices>

    <modal-opciones
      :turno="turno"
      :sucursal="sucursal"
      :callbackTurnos="getTurnos"
    ></modal-opciones>

    <modal-crear-turno
      :turno="turno"
      :sucursales="sucursales"
      :pacientes="pacientes"
      :callbackTurnos="getTurnos"
      :callbackPacientes="getPacientes"
    ></modal-crear-turno>

    <modal-sucursales
      :callbackSucursales="getSucursales"
      :cambiarSucursal="cambiarSucursal"
    ></modal-sucursales>

    <modal-editar-sucursales
      :sucursales="sucursalesEdit"
      :callbackSucursales="getSucursales"
    ></modal-editar-sucursales>

    <modal-editar-turno
      v-if="!$usuarioRecepcionista()"
      :turno="turno"
      :sucursales="sucursales"
      :pacientes="pacientes"
      :callbackTurnos="getTurnos"
      :callbackPacientes="getPacientes"
    ></modal-editar-turno>

    <modal-asignar-proxima-turno
      :turno="turno"
      :sucursales="sucursales"
      :pacientes="pacientes"
      :callbackTurnos="getTurnos"
      :callbackPacientes="getPacientes"
    ></modal-asignar-proxima-turno>

    <modal-dejar-comentarios :turno="turno"></modal-dejar-comentarios>

    <modal-modificar-medio-de-pago
      :turno="turno"
    ></modal-modificar-medio-de-pago>

    <modal-agregar-zonas :turno="turno"></modal-agregar-zonas>

    <modal-eliminar-turno
      v-if="!$usuarioRecepcionista()"
      :turno="turno"
      :callbackTurnos="getTurnos"
    ></modal-eliminar-turno>

    <modal-historial-de-turnos
      :callbackPlanilla="cambiarPlanilla"
    ></modal-historial-de-turnos>

    <modal-pacientes-sin-proximo-turno
      :turnos="pacientesSinProximoTurno"
      :callbackPlanilla="cambiarPlanilla"
    ></modal-pacientes-sin-proximo-turno>

    <modal-reservar-fechas
      :sucursales="sucursales"
      :callbackFechas="getFechas"
    ></modal-reservar-fechas>

    <modal-asignar-recepcionistas
      :sucursales="sucursales"
      :recepcionistas="recepcionistas"
    ></modal-asignar-recepcionistas>

    <div class="row justify-content-center mt-4">
      <div class="mt-4 col-md-6 col-12" v-if="$usuarioAdmin()">
        <div
          class="card"
          style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
        >
          <div class="card-header">Recepcionistas</div>
          <div class="card-body">
            <div class="row">
              <div
                class="col-12 text-center"
                v-for="recepcionista in recepcionistas"
                :key="recepcionista.identificador"
              >
                <strong>{{ recepcionista.identificador }}</strong>
                {{ recepcionista.nombre }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 col-md-6 col-12">
        <div
          class="card"
          style="box-shadow: 0 0 25px -5px #9e9c9e; border: none"
        >
          <div class="card-header">Opciones</div>
          <div class="card-body">
            <button
              v-if="$usuarioAdmin()"
              type="button"
              data-toggle="modal"
              href="#reservarFechasModal"
              class="btn btn-primary btn-block btn-lg mb-4"
            >
              Reservar fechas
            </button>

            <button
              v-if="$usuarioAdmin()"
              type="button"
              data-toggle="modal"
              href="#asignarRecepcionistasModal"
              class="btn btn-dark btn-block btn-lg mb-4"
            >
              Asignar recepcionistas
            </button>

            <button
              type="button"
              class="btn btn-danger btn-block btn-lg"
              @click="cerrarSesion"
            >
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {
      loader: null,
      sucursales: [],
      sucursalesEdit: [],
      sucursal: null,
      fechas: [],
      fecha: {},
      turnos: [],
      turno: {},
      interval: null,
      pacientes: [],
      recepcionistas: [],
      fechaHistorial: null,
      pacientesSinProximoTurno: [],
    };
  },
  watch: {
    sucursales(sucursales) {
      this.sucursalesEdit = JSON.parse(JSON.stringify(sucursales));
    },
    sucursal(sucursal) {
      if (!this.loader) this.loader = this.$loading.show();
      if (sucursal) {
        localStorage.setItem("sucursal", JSON.stringify(sucursal));
        this.getFechas();
      }
    },
    fecha(fecha) {
      if (fecha) {
        this.getTurnos();
        this.interval = setInterval(() => {
          this.getTurnos();
        }, 5 * 60 * 1000);
      } else {
        clearInterval(this.interval);
      }
    },
  },
  created() {
    if (localStorage.getItem("dia")) {
      const dia1 = localStorage.getItem("dia");
      const dia2 = moment().format("YYYY-MM-DD");

      if (dia1 !== dia2) {
        this.cerrarSesion();
        return;
      }
    }

    if (localStorage.getItem("sucursal"))
      this.sucursal = JSON.parse(localStorage.getItem("sucursal"));
    this.loader = this.$loading.show();
    this.getSucursales();
    this.getPacientes();
    this.getRecepcionistas();
  },
  methods: {
    cambiarPlanilla(fecha, sucursal) {
      if (this.sucursal.sucursal !== sucursal) {
        this.sucursal = Object.values(this.sucursales).find(
          (s) => s.sucursal === sucursal
        );

        this.fechaHistorial = fecha;
      } else {
        const nuevaFecha = Object.values(this.fechas).find(
          (f) => f.fecha === fecha
        );

        if (nuevaFecha) this.fecha = nuevaFecha;
      }
    },
    getRecepcionistas() {
      this.axios
        .get("recepcionistas")
        .then((r) => (this.recepcionistas = r.data));
    },
    cambiarSucursal(sucursal) {
      this.sucursal = sucursal;
    },
    cambiarParametros() {
      if (!this.loader) this.loader = this.$loading.show();
    },
    cerrarSesion() {
      const recepcionista = this.$usuarioRecepcionista();
      localStorage.clear();
      clearInterval(this.interval);

      this.$router.push(recepcionista ? "/recepcionista" : "/ingresar");
    },
    setTurno(turno) {
      this.turno = turno;
    },
    getPacientes() {
      this.axios.get("pacientes").then((r) => (this.pacientes = r.data));
    },
    getSucursales() {
      this.axios.get("sucursales").then((r) => {
        this.sucursales = r.data;
        this.sucursal = this.sucursal ? this.sucursal : this.sucursales[0];
      });
    },
    getFechas() {
      this.axios.get(`fechas/${this.sucursal.sucursal}`).then((r) => {
        this.fechas = r.data;

        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);

        let buscarFechaExitoso = false;

        if (this.fechaHistorial) {
          buscarFechaExitoso = Object.values(this.fechas).find(
            (f) => f.fecha === this.fechaHistorial
          );
          this.fechaHistorial = null;
        }

        if (!buscarFechaExitoso)
          this.fecha = JSON.parse(JSON.stringify(this.fechas)).sort(
            (f1, f2) => {
              const parts1 = f1.fecha.split("-");
              const fecha1 = new Date(parts1[0], parts1[1] - 1, parts1[2]);

              const parts2 = f2.fecha.split("-");
              const fecha2 = new Date(parts2[0], parts2[1] - 1, parts2[2]);

              return Math.abs(hoy - fecha1) - Math.abs(hoy - fecha2);
            }
          )[0];
        else this.fecha = buscarFechaExitoso;
      });
    },
    getTurnos() {
      this.axios
        .get(`turnos/${this.sucursal.sucursal}/${this.fecha.fecha}`)
        .then((r) => {
          this.turnos = r.data;

          this.turnos.push(
            ...Vue.prototype.$horarios
              .filter((h) => !this.turnos.some((t) => t.hora === h))
              .map((h) => {
                return {
                  id: null,
                  fecha: this.fecha.fecha,
                  hora: h,
                  sucursal: this.sucursal.sucursal,
                  paciente: null,
                  celular: null,
                  alex: false,
                  zonas: [],
                  sesion: null,
                  monto: null,
                  estado: null,
                  mp: null,
                  proximoTurno: null,
                  comentarios: null,
                };
              })
          );

          this.turnos.sort(
            (t1, t2) =>
              parseInt(t1.hora.replace(":", "")) -
              parseInt(t2.hora.replace(":", ""))
          );

          if (this.loader) this.loader.hide();
          this.loader = null;
        });

      this.getPacientesSinProximoTurno();
    },
    getPacientesSinProximoTurno() {
      if (!this.$usuarioRecepcionista()) {
        this.axios
          .get("pacientes/sin-proximo-turno")
          .then((r) => (this.pacientesSinProximoTurno = r.data));
      }
    },
    getColorFondo(estado) {
      if (estado) {
        return this.$estados.find((e) => e.estado === estado).colorFondo;
      }
    },
    getColorTexto(estado) {
      if (estado) {
        return this.$estados.find((e) => e.estado === estado).colorTexto;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
td hr {
  margin: 0.25rem 0;
  &:last-child {
    display: none;
  }
}
.card-header {
  background: linear-gradient(to right, rgb(150, 47, 191), rgb(214, 41, 118));
  color: white;
}
tr {
  cursor: pointer;
}
th:nth-child(3) {
  width: 10rem;
}
th:last-child {
  width: 2rem;
}
.grisado {
  background: #f1f1f1;
}
</style>
