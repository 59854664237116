<template>
  <div
    class="modal fade"
    id="modalSucursales"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalSucursales"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalSucursalesLabel">Sucursales</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Cerrar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label for="nombreSucursalInput">Nombre sucursal</label>
              <input
                type="text"
                class="form-control"
                id="nombreSucursalInput"
                v-model="sucursal.sucursal"
                placeholder="Nombre sucursal"
              />
            </div>
            <div class="form-group">
              <label for="direccionSucursalInput">Direccion</label>
              <input
                type="text"
                class="form-control"
                v-model="sucursal.direccion"
                id="direccionSucursalInput"
                placeholder="Direccion"
              />
            </div>
            <div class="form-group">
              <label for="entreCallesSucursalInput">Entre calles</label>
              <input
                type="text"
                class="form-control"
                v-model="sucursal.entreCalles"
                id="entreCallesSucursalInput"
                placeholder="Entre calles"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="agregarSucursal"
          >
            Agregar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["callbackSucursales", "cambiarSucursal"],
  data() {
    return {
      sucursal: {},
      fechas: [],
      calendario: [],
    };
  },
  methods: {
    async agregarSucursal() {
      try {
        await this.axios.post("sucursales", { ...this.sucursal, activo: 1 });
        this.callbackSucursales();
        this.sucursal.activo = 1;
        localStorage.setItem("sucursal", JSON.stringify(this.sucursal));
        this.cambiarSucursal(this.sucursal);
      } catch (err) {
        alert("Ya existe una sucursal con ese nombre");
      }
    },
  },
};
</script>
