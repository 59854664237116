<template>
  <div
    class="modal fade"
    id="agregarZonasModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="agregarZonasModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="agregarZonasModalLabel">Agregar zonas</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <div class="form-check" v-for="zona in $zonas1" :key="zona">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="zona"
                  :value="zona"
                  :disabled="$zonaYaCargada(turno, zona)"
                  v-model="zonas"
                />
                <label class="form-check-label" :for="zona">{{ zona }}</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-check" v-for="zona in $zonas2" :key="zona">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="zona"
                  :value="zona"
                  :disabled="$zonaYaCargada(turno, zona)"
                  v-model="zonas"
                />
                <label class="form-check-label" :for="zona">{{ zona }}</label>
              </div>
            </div>
            <div class="col-4">
              <div class="form-check" v-for="zona in $zonas3" :key="zona">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="zona"
                  :value="zona"
                  :disabled="$zonaYaCargada(turno, zona)"
                  v-model="zonas"
                />
                <label class="form-check-label" :for="zona">{{ zona }}</label>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="form-check" v-for="zona in $zonas4" :key="zona">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :id="zona"
                  :value="zona"
                  :disabled="$zonaYaCargada(turno, zona)"
                  v-model="zonas"
                />
                <label class="form-check-label" :for="zona">{{ zona }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="agregarZonas"
          >Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turno"],
  data() {
    return {
      zonas: []
    };
  },
  watch: {
    turno() {
      this.zonas = this.turno.zonas;
    }
  },
  methods: {
    agregarZonas() {
      this.turno.zonas = this.zonas;

      let loader = this.$loading.show();
      this.axios.post("turnos/editar", this.turno).finally(loader.hide());
    }
  }
};
</script>
