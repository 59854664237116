<template>
    <div
        class="modal fade"
        id="historialDeTurnosModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="historialDeTurnosModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-lg modal-fullscreen" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="historialDeTurnosModalLabel">Historial de turnos</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="getHistorial">
                        <div class="form-row align-items-center">
                            <div class="col-10">
                                <label class="sr-only" for="buscarPacienteInput">Paciente</label>
                                <input
                                    required
                                    autofocus
                                    type="text"
                                    class="form-control"
                                    id="buscarPacienteInput"
                                    placeholder="Paciente"
                                    v-model="paciente"
                                />
                            </div>
                            <div class="col-2">
                                <button type="submit" class="btn btn-primary w-100">Buscar</button>
                            </div>
                        </div>
                    </form>

                    <div class="table-responsive mt-4" v-if="turnos.length">
                        <table class="table table-bordered mb-0">
                            <thead>
                                <th>Fecha y Hora</th>
                                <th>Paciente</th>
                                <th>Zonas</th>
                                <th>Sesión</th>
                                <th>Monto</th>
                                <th>MP</th>
                                <th>Sucursal</th>
                                <th>Comentarios</th>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="turno in turnos"
                                    :key="turno.id"
                                    data-dismiss="modal"
                                    data-toggle="modal"
                                    @click="callbackPlanilla(turno.fecha, turno.sucursal)"
                                >
                                    <td>
                                        {{ turno.fecha | formatFecha }}
                                        <br />
                                        {{ turno.hora }}
                                    </td>
                                    <td>
                                        <template v-if="turno.alex">
                                            <strong>
                                                <u>ALEX</u>
                                            </strong>
                                            <br />
                                        </template>
                                        {{ turno.paciente }}
                                    </td>
                                    <td>
                                        <template v-for="zona in turno.zonas">
                                            {{ zona }}
                                            <hr :key="zona" />
                                        </template>
                                    </td>
                                    <td>{{ turno.sesion }}</td>
                                    <td>{{ turno.monto | formatMontoTabla }}</td>
                                    <td>{{ turno.mp }}</td>
                                    <td>{{ turno.sucursal }}</td>
                                    <td>{{ turno.comentarios }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["callbackPlanilla"],
    data() {
        return {
            paciente: "",
            turnos: []
        };
    },
    methods: {
        getHistorial() {
            this.paciente = this.paciente.trim();

            let loader = this.$loading.show();
            this.axios
                .post("turnos/historial", { paciente: this.paciente })
                .then(r => (this.turnos = r.data))
                .finally(loader.hide());
        }
    }
};
</script>

<style lang="scss" scoped>
td hr {
    margin: 0.25rem 0;
    &:last-child {
        display: none;
    }
}
tr {
    cursor: pointer;
}
th:nth-child(3) {
    width: 10rem;
}
th:last-child {
    width: 2rem;
}
</style>
