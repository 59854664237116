<template>
  <div
    class="modal fade"
    id="opcionesModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="opcionesModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="opcionesModalLabel">{{ turno.hora }} | Opciones del turno</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <button
            type="button"
            class="btn btn-primary btn-block"
            data-dismiss="modal"
            data-toggle="modal"
            href="#crearTurnoModal"
          >Crear nuevo turno</button>
          <template v-if="turno.id">
            <button
              type="button"
              class="btn btn-secondary btn-block"
              data-dismiss="modal"
              data-toggle="modal"
              href="#editarTurnoModal"
              v-if="!$usuarioRecepcionista()"
            >Editar turno existente</button>
            <button
              type="button"
              class="btn btn-success btn-block"
              data-dismiss="modal"
              data-toggle="modal"
              href="#asignarProximoTurnoModal"
            >Asignar próximo turno</button>
            <button
              type="button"
              class="btn btn-danger btn-block"
              data-dismiss="modal"
              data-toggle="modal"
              href="#dejarComentariosModal"
            >Dejar comentarios</button>
            <button
              type="button"
              class="btn btn-success btn-block"
              data-dismiss="modal"
              data-toggle="modal"
              href="#modificarMedioDePagoModal"
            >Modificar medio de pago</button>
            <button
              type="button"
              class="btn btn-primary btn-block"
              data-dismiss="modal"
              data-toggle="modal"
              href="#agregarZonasModal"
            >Agregar zonas</button>
            <button
              type="button"
              class="btn btn-danger btn-block"
              data-dismiss="modal"
              data-toggle="modal"
              href="#eliminarTurnoModal"
              v-if="!$usuarioRecepcionista()"
            >Eliminar turno</button>

            <template v-if="!$usuarioRecepcionista()">
              <h5 class="mt-3 mb-3">Enviar mensaje</h5>

              <a
                :href="`https://wa.me/${turno.celular}`"
                target="_blank"
                class="btn btn-success text-white btn-block"
              >Enviar mensaje</a>
              <a
                :href="`https://wa.me/${turno.celular}?text=${$mensaje1(turno, sucursal)}`"
                target="_blank"
                class="btn btn-success text-white btn-block"
              >Recordatorio 1 día</a>
              <a
                :href="`https://wa.me/${turno.celular}?text=${$mensaje6(turno, sucursal)}`"
                target="_blank"
                class="btn btn-success text-white btn-block"
              >Recordatorio 6 días</a>
            </template>

            <h5 class="mt-3 mb-3">Cambiar estado</h5>

            <button
              type="button"
              class="btn btn-light btn-block"
              data-dismiss="modal"
              @click="cambiarEstado(null)"
            >Vacío</button>
            <button
              v-for="estado in estados"
              :key="estado.estado"
              :style="{ 'background': estado.colorFondo, 'color': estado.colorTexto }"
              type="button"
              class="btn btn-block"
              data-dismiss="modal"
              @click="cambiarEstado(estado.estado)"
            >{{ estado.estado }}</button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["turno", "sucursal", "callbackTurnos"],
  data() {
    return {
      estados: this.$usuarioRecepcionista()
        ? this.$estadosRecepcionistas
        : this.$estadosGestion
    };
  },
  methods: {
    cambiarEstado(estado) {
      this.turno.estado = estado;
      this.axios
        .post("api/turnos/editar", this.turno)
        .then(() => this.callbackTurnos());
    }
  }
};
</script>
