<template>
  <div
    class="modal fade"
    id="reservarFechasModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="reservarFechasModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="reservarFechasModalLabel"></h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Cerrar"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <select class="form-control" v-model="sucursal" @change="getFechas">
            <option
              v-for="_sucursal in sucursales"
              :value="_sucursal"
              :key="_sucursal.sucursal"
            >
              {{ _sucursal.sucursal }}
            </option>
          </select>
          <div class="pl-4 w-100 mt-4">
            <v-calendar
              v-bind="readOnlyAttributes"
              :attributes="attributes"
              @dayclick="dayClicked"
            ></v-calendar>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cerrar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            @click="reservarFechas"
          >
            Aceptar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["sucursales", "callbackFechas"],
  data() {
    return {
      selectedDate: null,
      sucursal: null,
      calendario: [],
      attributes: [],
    };
  },
  watch: {
    sucursales() {
      this.sucursal = JSON.parse(localStorage.getItem("sucursal"));
      this.getFechas();
    },
  },
  computed: {
    readOnlyAttributes: function () {
      if (this.sucursal != null) {
        return this.sucursal.activo == 1
          ? { "available-dates": { start: new Date(), end: null } }
          : { "disabled-dates": { weekdays: [1, 2, 3, 4, 5, 6, 7] } };
      }
    },
    sucursalesFiltradas: function () {
      return this.sucursales.filter((sucursal) => sucursal.activo == 1);
    },
  },
  created() {
    let inicio = moment().subtract(3, "months").startOf("month");
    let fin = moment().add(5, "months").endOf("month");

    while (inicio <= fin) {
      this.calendario.push(inicio.format("YYYY-MM-DD"));
      inicio = inicio.clone().add(1, "days");
    }
  },
  methods: {
    dayClicked({ date, attributes }) {
      if (attributes) {
        this.attributes = this.attributes.filter(
          ({ dates }) => dates.getTime() != date.getTime()
        );
      } else {
        this.attributes.push({
          highlight: "purple",
          contentStyle: {
            color: "white",
          },
          dates: date,
        });
      }
    },
    getFechas() {
      this.axios.get(`fechas/${this.sucursal.sucursal}`).then((r) => {
        this.attributes = r.data.map((f) => {
          let dia = new Date(f.fecha);
          dia.setHours(dia.getHours() + 3);
          return {
            highlight: "purple",
            contentStyle: {
              color: "white",
            },
            dates: dia,
          };
        });
      });
    },
    reservarFechas() {
      if (this.sucursal.activo == 1) {
        let loader = this.$loading.show();
        const fechas = this.attributes.map(
          ({ dates }) =>
            `${dates.getFullYear()}-${dates.getMonth() + 1}-${dates.getDate()}`
        );
        this.axios
          .post("reservar-fechas", {
            sucursal: this.sucursal.sucursal,
            fechas,
          })
          .then(() => this.callbackFechas())
          .finally(loader.hide());
      } else {
        alert("La sucursal se encuentra desactivada");
      }
    },
  },
};
</script>
