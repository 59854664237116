import { render, staticRenderFns } from "./ModalAsignarProximoTurnoComponent.vue?vue&type=template&id=1fa9877e&scoped=true&"
import script from "./ModalAsignarProximoTurnoComponent.vue?vue&type=script&lang=js&"
export * from "./ModalAsignarProximoTurnoComponent.vue?vue&type=script&lang=js&"
import style0 from "./ModalAsignarProximoTurnoComponent.vue?vue&type=style&index=0&id=1fa9877e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fa9877e",
  null
  
)

export default component.exports