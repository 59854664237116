require('./bootstrap');

window.Vue = require('vue');

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import VCalendar from "v-calendar";
Vue.use(VCalendar);

import VueAxios from 'vue-axios';
import axios from 'axios';
axios.defaults.baseURL = process.env.MIX_API_URL;

import App from './App.vue';
Vue.use(VueAxios, axios);

Vue.prototype.$zonas1 = [
    'Pierna entera',
    'Media pierna',
    'Cavado completo',
    'Cavado simple',
    'Tira de cola',
    'Axilas'
];
Vue.prototype.$zonas2 = ['Rostro completo', 'Bozo', 'Frente', 'Patillas', 'Mentón', 'Barba'];
Vue.prototype.$zonas3 = [
    'Cuerpo entero',
    'Tira de abdomen',
    'Brazo',
    'Medio brazo',
    'Espalda',
    'Cintura'
];
Vue.prototype.$zonas4 = ['Pecho', 'Glúteos', 'Pies'];

Vue.prototype.$estadosGestion = [
    {
        estado: 'Confirmación 1 día',
        colorFondo: '#e1bee7',
        colorTexto: '#ab47bc'
    },
    {
        estado: 'Confirmación 6 días',
        colorFondo: '#c5cae9',
        colorTexto: '#5c6bc0'
    },
    {
        estado: 'Dejó en visto',
        colorFondo: '#ffcdd2',
        colorTexto: '#ef5350'
    },
    {
        estado: 'No contesta',
        colorFondo: '#ef9a9a',
        colorTexto: '#b71c1c'
    },
    {
        estado: 'No confirmó',
        colorFondo: '#ff8f00',
        colorTexto: '#c43e00'
    }
];

Vue.prototype.$estadosRecepcionistas = [
    {
        estado: 'Ausente',
        colorFondo: '#ff1744',
        colorTexto: '#4F0000'
    },
    {
        estado: 'En espera',
        colorFondo: '#81d4fa',
        colorTexto: '#01579b'
    },
    {
        estado: 'Siendo atendida',
        colorFondo: '#fff59d',
        colorTexto: '#f57f17'
    },
    {
        estado: 'Ya se atendió',
        colorFondo: '#a5d6a7',
        colorTexto: '#2e7d32'
    }
];

Vue.prototype.$estados = [
    ...Vue.prototype.$estadosGestion,
    ...Vue.prototype.$estadosRecepcionistas
];

Vue.prototype.$horarios = [
    '08:00',
    '08:15',
    '08:30',
    '08:45',
    '09:00',
    '09:15',
    '09:30',
    '09:45',
    '10:00',
    '10:15',
    '10:30',
    '10:45',
    '11:00',
    '11:15',
    '11:30',
    '11:45',
    '12:00',
    '12:15',
    '12:30',
    '12:45',
    '13:00',
    '13:15',
    '13:30',
    '13:45',
    '14:00',
    '14:15',
    '14:30',
    '14:45',
    '15:00',
    '15:15',
    '15:30',
    '15:45',
    '16:00',
    '16:15',
    '16:30',
    '16:45',
    '17:00',
    '17:15',
    '17:30',
    '17:45',
    '18:00',
    '18:15',
    '18:30',
    '18:45',
    '19:00',
    '19:15',
    '19:30',
    '19:45',
    '20:00',
    '20:15',
    '20:30',
    '20:45'
];

Vue.prototype.$usuarioAdmin = function() {
    return localStorage.getItem('admin');
};
Vue.prototype.$usuarioGestion = function() {
    return localStorage.getItem('gestion');
};
Vue.prototype.$usuarioRecepcionista = function() {
    return localStorage.getItem('recepcionista');
};

Vue.prototype.$zonaYaCargada = function(turno, zona) {
    return Vue.prototype.$usuarioRecepcionista() && (turno.zonas || []).includes(zona);
};

Vue.prototype.$enviarMensaje = function(celular) {
    window.open(`https://wa.me/${celular}`, '_blank');
};

Vue.prototype.$mensaje1 = function(turno, sucursal) {
    return `Hola! Te escribo de Alma Esthetics para recordarte que mañana, ${moment(
        turno.fecha
    ).format('DD/MM/YYYY')}, tienes un turno a las ${turno.hora}, en ${
        sucursal.direccion
    }, entre calles ${
        sucursal.entreCalles
    }. Por favor, recordar ir rasurada y llevar una toalla para limpiar el excedente de gel. Muchas gracias!`;
};
Vue.prototype.$mensaje6 = function(turno, sucursal) {
    return `Hola! Te escribo de Alma Esthetics para recordarte que tienes un turno el día ${moment(
        turno.fecha
    ).format('DD/MM/YYYY')} a las ${turno.hora}, en ${sucursal.direccion}, entre calles ${
        sucursal.entreCalles
    }. En caso de no poder asistir avísanos con la mayor anticipación posible. Muchas gracias!`;
};

import moment from 'moment';

Vue.config.productionTip = false;
Vue.filter('formatFecha', function(value) {
    if (value) return moment(String(value)).format('DD/MM/YYYY');
});
Vue.filter('formatHora', function(value) {
    if (value) return moment(String(value)).format('hh:mm');
});
Vue.filter('formatHora2', function(value) {
    if (value) return value.slice(0, -3);
});
Vue.filter('formatMontoBalance', function(value) {
    return `$ ${value || '---'}`;
});
Vue.filter('formatMontoTabla', function(value) {
    if (value) return `$${value}`;
});

import ModalOpcionesComponent from './components/ModalOpcionesComponent.vue';
Vue.component('modal-opciones', ModalOpcionesComponent);
import BalanceComponent from './components/BalanceComponent.vue';
Vue.component('balance', BalanceComponent);
import SinProximoTurnoComponent from './components/SinProximoTurnoComponent.vue';
Vue.component('sin-proximo-turno', SinProximoTurnoComponent);
import IndicesComponent from './components/IndicesComponent.vue';
Vue.component('indices', IndicesComponent);
import ModalEliminarTurnoComponent from './components/ModalEliminarTurnoComponent.vue';
Vue.component('modal-eliminar-turno', ModalEliminarTurnoComponent);
import ModalAgregarZonasTurnoComponent from './components/ModalAgregarZonasTurnoComponent.vue';
Vue.component('modal-agregar-zonas', ModalAgregarZonasTurnoComponent);
import ModalDejarComentariosTurnoComponent from './components/ModalDejarComentariosTurnoComponent.vue';
Vue.component('modal-dejar-comentarios', ModalDejarComentariosTurnoComponent);
import ModalCrearTurnoComponent from './components/ModalCrearTurnoComponent.vue';
Vue.component('modal-crear-turno', ModalCrearTurnoComponent);
import ModalAsignarProximoTurnoComponent from './components/ModalAsignarProximoTurnoComponent.vue';
Vue.component('modal-asignar-proxima-turno', ModalAsignarProximoTurnoComponent);
import ModalEditarTurnoComponent from './components/ModalEditarTurnoComponent.vue';
Vue.component('modal-editar-turno', ModalEditarTurnoComponent);
import ModalHistorialDeTurnosComponent from './components/ModalHistorialDeTurnosComponent.vue';
Vue.component('modal-historial-de-turnos', ModalHistorialDeTurnosComponent);
import ModalReservarFechasComponent from './components/ModalReservarFechasComponent.vue';
Vue.component('modal-reservar-fechas', ModalReservarFechasComponent);
import ModalAsignarRecepcionistasComponent from './components/ModalAsignarRecepcionistasComponent.vue';
Vue.component('modal-asignar-recepcionistas', ModalAsignarRecepcionistasComponent);
import ModalModificarMedioDePagoTurnoComponent from './components/ModalModificarMedioDePagoTurnoComponent.vue';
Vue.component('modal-modificar-medio-de-pago', ModalModificarMedioDePagoTurnoComponent);
import ModalPacientesSinProximoTurnoComponent from './components/ModalPacientesSinProximoTurnoComponent.vue';
Vue.component('modal-pacientes-sin-proximo-turno', ModalPacientesSinProximoTurnoComponent);
import ModalSucursalesComponent from './components/ModalSucursalesComponent.vue';
Vue.component('modal-sucursales', ModalSucursalesComponent);
import ModalEditarSucursalesComponent from './components/ModalEditarSucursalesComponent.vue';
Vue.component('modal-editar-sucursales', ModalEditarSucursalesComponent);

import TurnosComponent from './components/TurnosComponent.vue';
import IngresarComponent from './components/IngresarComponent.vue';
import RecepcionistasComponent from './components/RecepcionistasComponent.vue';

const routes = [
    {
        name: 'ingresar',
        path: '/ingresar',
        component: IngresarComponent
    },
    {
        name: 'turnos',
        path: '/',
        component: TurnosComponent,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'recepcionista',
        path: '/recepcionista',
        component: RecepcionistasComponent
    },
    {
        path: '*',
        component: TurnosComponent,
        meta: {
            requiresAuth: true
        }
    }
];

const router = new VueRouter({ mode: 'history', routes: routes });

router.beforeEach((to, _, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('ingresado') == null) {
            next({
                path: '/ingresar'
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

const app = new Vue(Vue.util.extend({ router }, App)).$mount('#app');
