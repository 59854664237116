<template>
  <div
    class="modal fade"
    id="asignarRecepcionistasModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="asignarRecepcionistasModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="asignarRecepcionistasModalLabel">Asignar recepcionistas</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <select class="form-control" v-model="sucursal" @change="getFechas">
            <option
              v-for="_sucursal in sucursales"
              :value="_sucursal"
              :key="_sucursal.sucursal"
            >{{ _sucursal.sucursal }}</option>
          </select>
          <div class="pl-4 w-100 mt-4">
            <div class="form-group" v-for="fecha in fechas" :key="fecha.fecha | formatFecha">
              <div class="row">
                <div class="offset-2 col-3 align-self-center">{{ fecha.fecha | formatFecha }}</div>
                <div class="col-4">
                  <select
                    class="form-control"
                    v-model="fecha.recepcionista"
                    @change="asignarRecepcionista(fecha)"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="recepcionista in recepcionistas"
                      :value="recepcionista.identificador"
                      :key="recepcionista.identificador"
                    >{{ recepcionista.nombre }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["sucursales", "recepcionistas"],
  data() {
    return {
      sucursal: null,
      fechas: []
    };
  },
  watch: {
    sucursales() {
      this.sucursal = JSON.parse(localStorage.getItem("sucursal"));
      this.getFechas();
    }
  },
  methods: {
    getFechas() {
      this.axios.get(`fechas/${this.sucursal.sucursal}`).then(r => {
        this.fechas = r.data;
      });
    },
    asignarRecepcionista(fecha) {
      this.axios.post("asignar-recepcionista", {
        sucursal: this.sucursal.sucursal,
        fecha: fecha.fecha,
        recepcionista: fecha.recepcionista
      });
    }
  }
};
</script>
