<template>
  <div
    class="modal fade"
    id="crearTurnoModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="crearTurnoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="crearTurnoModalLabel">Crear nuevo turno</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Cerrar">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="sucursalSelect">Sucursal</label>
            <select
              class="form-control"
              id="sucursalSelect"
              :disabled="$usuarioRecepcionista()"
              v-model="nuevoTurno.sucursal"
              @change="getFechas"
            >
              <option
                v-for="sucursal in sucursales"
                :value="sucursal.sucursal"
                :key="sucursal.sucursal"
              >{{ sucursal.sucursal }}</option>
            </select>
          </div>
          <div class="row form-group">
            <div class="col-6">
              <label for="fechaSelect">Fecha</label>
              <select
                class="form-control"
                id="fechaSelect"
                v-model="nuevoTurno.fecha"
                @change="getTurnos"
              >
                <option
                  v-for="fecha in fechas"
                  :value="fecha.fecha"
                  :key="fecha.id"
                >{{ fecha.fecha | formatFecha }}</option>
              </select>
            </div>
            <div class="col-6">
              <label for="horaSelect">Hora</label>
              <select class="form-control" id="horaSelect" v-model="nuevoTurno.hora">
                <option
                  v-for="hora in $horarios"
                  :value="hora"
                  :key="`${nuevoTurno.sucursal}${nuevoTurno.fecha}${hora}`"
                >{{ hora + (esSobreturno(hora) ? ' (SOBRETURNO)' : '') }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <div :class="{'col-6' : !$usuarioRecepcionista(), 'col-12' : $usuarioRecepcionista()}">
              <label for="pacienteInputCrear">Paciente</label>
              <input
                type="text"
                class="form-control"
                id="pacienteInputCrear"
                placeholder="Paciente"
                v-model="nuevoTurno.paciente"
                @keypress="buscarPaciente = null"
              />
            </div>
            <div class="col-6" v-if="!$usuarioRecepcionista()">
              <label for="celularInputCrear">Celular</label>
              <input
                type="text"
                class="form-control"
                id="celularInputCrear"
                placeholder="Celular"
                v-model="nuevoTurno.celular"
              />
            </div>
          </div>
          <div class="form-group" v-if="nuevoTurno.paciente">
            <label class="pacientesConocidas" for="pacientesConocidasSelect">
              Pacientes conocidas
              <button
                type="button"
                class="float-right btn btn-danger btn-sm"
                v-if="buscarPaciente"
                @click="eliminarPaciente"
              >Borrar paciente</button>
            </label>
            <select
              class="form-control"
              id="pacientesConocidasSelect"
              size="5"
              @change="setPaciente"
              v-model="buscarPaciente"
            >
              <option
                v-for="paciente in filtrarPacientes"
                :value="paciente"
                :key="paciente.id"
              >{{ paciente.nombre }}{{ !$usuarioRecepcionista() ? ` (${paciente.celular})` : '' }}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Zonas</label>
            <div class="row">
              <div class="col-4">
                <div class="form-check" v-for="zona in $zonas1" :key="zona">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="zona"
                    :value="zona"
                    v-model="nuevoTurno.zonas"
                  />
                  <label class="form-check-label" :for="zona">{{ zona }}</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-check" v-for="zona in $zonas2" :key="zona">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="zona"
                    :value="zona"
                    v-model="nuevoTurno.zonas"
                  />
                  <label class="form-check-label" :for="zona">{{ zona }}</label>
                </div>
              </div>
              <div class="col-4">
                <div class="form-check" v-for="zona in $zonas3" :key="zona">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="zona"
                    :value="zona"
                    v-model="nuevoTurno.zonas"
                  />
                  <label class="form-check-label" :for="zona">{{ zona }}</label>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-4">
                <div class="form-check" v-for="zona in $zonas4" :key="zona">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :id="zona"
                    :value="zona"
                    v-model="nuevoTurno.zonas"
                  />
                  <label class="form-check-label" :for="zona">{{ zona }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-6">
              <label for="montoInput">Monto</label>
              <input
                type="number"
                class="form-control"
                id="montoInput"
                placeholder="Monto"
                v-model="nuevoTurno.monto"
              />
            </div>
            <div class="col-6">
              <label for="medioDePagoSelect">Medio de pago</label>
              <select class="form-control" id="medioDePagoSelect" v-model="nuevoTurno.mp">
                <option>Efectivo</option>
                <option>MercadoPago</option>
              </select>
            </div>
          </div>
          <div class="form-group">
            <label for="comentariosTextarea">Comentarios</label>
            <textarea
              class="form-control"
              id="comentariosTextarea"
              rows="3"
              placeholder="Opcional"
              v-model="nuevoTurno.comentarios"
            ></textarea>
          </div>
          <div class="row form-group">
            <div class="col-6">
              <label class="w-100">ALEX</label>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="alexNoRadio"
                  :value="false"
                  v-model="nuevoTurno.alex"
                />
                <label class="form-check-label" for="alexNoRadio" checked>No</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  id="alexSiRadio"
                  :value="true"
                  v-model="nuevoTurno.alex"
                />
                <label class="form-check-label" for="alexSiRadio">Sí</label>
              </div>
            </div>
            <div class="col-6">
              <label for="sesionInput">Sesión</label>
              <input
                type="number"
                class="form-control"
                id="sesionInput"
                placeholder="Sesión"
                v-model="nuevoTurno.sesion"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            :disabled="!formularioValido"
            @click="crearTurno"
          >Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "turno",
    "sucursales",
    "pacientes",
    "callbackTurnos",
    "callbackPacientes"
  ],
  data() {
    return {
      nuevoTurno: {},
      buscarPaciente: null,
      turnos: [],
      fechas: []
    };
  },
  watch: {
    turno() {
      this.nuevoTurno = {
        sucursal: this.turno.sucursal,
        fecha: this.turno.fecha,
        hora: this.turno.hora,
        celular: "",
        mp: "Efectivo",
        alex: false,
        sesion: 1,
        zonas: []
      };
      this.getFechas();
      this.buscarPaciente = null;
    }
  },
  methods: {
    crearTurno() {
      this.nuevoTurno.paciente = this.nuevoTurno.paciente.trim();
      this.nuevoTurno.celular = this.nuevoTurno.celular.trim();
      this.nuevoTurno.celular = this.nuevoTurno.celular.startsWith("549")
        ? this.nuevoTurno.celular
        : `549${this.nuevoTurno.celular}`;
      if (this.nuevoTurno.comentarios)
        this.nuevoTurno.comentarios = this.nuevoTurno.comentarios.trim();

      let loader = this.$loading.show();
      this.axios
        .post("turnos/crear", this.nuevoTurno)
        .then(() => this.callbackTurnos())
        .finally(loader.hide());

      if (this.pacienteModificado) {
        let paciente = {
          id: this.buscarPaciente.id,
          nombre: this.nuevoTurno.paciente,
          celular: this.nuevoTurno.celular
        };
        this.axios
          .post("paciente/editar", paciente)
          .then(() => this.callbackPacientes());
      } else if (!this.buscarPaciente) {
        this.axios
          .post("paciente/crear", {
            nombre: this.nuevoTurno.paciente,
            celular: this.nuevoTurno.celular
          })
          .then(() => this.callbackPacientes());
      }
    },
    esSobreturno(hora) {
      return this.turnos.some(t => t.hora === hora);
    },
    setPaciente() {
      if (this.buscarPaciente) {
        this.nuevoTurno.paciente = this.buscarPaciente.nombre;
        this.nuevoTurno.celular = this.buscarPaciente.celular;
      }
    },
    eliminarPaciente() {
      if (this.buscarPaciente) {
        this.axios
          .post("paciente/eliminar", { id: this.buscarPaciente.id })
          .then(() => this.callbackPacientes());
        this.buscarPaciente = null;
      }
    },
    getFechas() {
      if (!this.nuevoTurno.sucursal) return;

      this.axios.get(`fechas/${this.nuevoTurno.sucursal}`).then(r => {
        this.fechas = r.data;

        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);

        if (!this.fechas.some(f => f.fecha === this.nuevoTurno.fecha)) {
          this.nuevoTurno.fecha = JSON.parse(JSON.stringify(this.fechas)).sort(
            (f1, f2) => {
              const parts1 = f1.fecha.split("-");
              const fecha1 = new Date(parts1[0], parts1[1] - 1, parts1[2]);

              const parts2 = f2.fecha.split("-");
              const fecha2 = new Date(parts2[0], parts2[1] - 1, parts2[2]);

              return Math.abs(hoy - fecha1) - Math.abs(hoy - fecha2);
            }
          )[0].fecha;
        }
        this.getTurnos();
      });
    },
    getTurnos() {
      if (!this.nuevoTurno.sucursal || !this.nuevoTurno.fecha) return;

      this.axios
        .get(`turnos/${this.nuevoTurno.sucursal}/${this.nuevoTurno.fecha}`)
        .then(r => {
          this.turnos = r.data;
        });
    }
  },
  computed: {
    pacienteModificado() {
      return (
        this.buscarPaciente &&
        this.filtrarPacientes.length &&
        (this.buscarPaciente.nombre !== this.nuevoTurno.paciente ||
          this.buscarPaciente.celular !== this.nuevoTurno.celular)
      );
    },
    filtrarPacientes() {
      const busqueda = this.nuevoTurno.paciente
        ? this.nuevoTurno.paciente.toLowerCase()
        : "";
      return this.pacientes
        .filter(p => p.nombre.toLowerCase().includes(busqueda))
        .slice(0, 6);
    },
    formularioValido() {
      return (
        this.nuevoTurno.paciente &&
        (this.nuevoTurno.celular || this.$usuarioRecepcionista()) &&
        this.nuevoTurno.monto &&
        this.nuevoTurno.sesion &&
        this.nuevoTurno.zonas.length
      );
    }
  }
};
</script>

<style scoped>
.pacientesConocidas {
  line-height: 28px;
  width: 100%;
}
</style>
